import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface enterpriseHoldersState {
  enterpriseHolders: any;
}

const initialState: enterpriseHoldersState = {
  enterpriseHolders: undefined,
};

export const enterpriseHoldersSlice = createSlice({
  name: 'enterpriseHolders',
  initialState,
  reducers: {
    setenterpriseHolders: (state, action: PayloadAction<any>) => {
      state.enterpriseHolders = action.payload;
    },
  },
});

export const { setenterpriseHolders } = enterpriseHoldersSlice.actions;

export default enterpriseHoldersSlice.reducer;
