import userSocialInfoSlice from './userSocialInfoSlice';
import userClaimStatus from './userClaimStatusSlice';
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './claimProfit';
import enterpriseDetail from './enterpriseDetails';
import enterpriseProjectInfo from './enterpriseProjectInfo';
import enterpriseCompaigns from './enterpriseCompaigns';
import enterpriseOutReachUsers from './enterpriseOutReachUsers';
import enterpriseSimilarProjects from './enterpriseSimilarProjects';
import enterpriseAttributes from './enterpriseAttributes';
import enterpriseHolders from './enterpriseHolders';
import enterpriseUsers from './enterpriseUsers';
import claimPoints from './claimPoint';
import consumerPoints from './consumerPoints';
import enterprisePoints from './enterprisePoints';
import consumerInfo from './consumerInfo';
import spotsList from './trendingspots';
import occupiedSpots from './occupiedSpots';
const store = configureStore({
  reducer: {
    counter: counterReducer,
    userClaimStatus,
    userSocialInfoSlice,
    enterpriseDetail,
    enterpriseProjectInfo,
    enterpriseCompaigns,
    enterpriseOutReachUsers,
    enterpriseSimilarProjects,
    enterpriseAttributes,
    enterpriseHolders,
    enterpriseUsers,
    claimPoints,
    consumerPoints,
    enterprisePoints,
    consumerInfo,
    spotsList,
    occupiedSpots,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
