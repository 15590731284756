'use client';
import { useInterceptors } from '@/common/useInterceptors';
import { basicConfig } from '@/configs';
import { FirebaseProviderType, UserType } from '@/configs/firebase';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { AxiosInstance } from 'axios';
import { set } from 'cypress/types/lodash';
import { User, UserCredential } from 'firebase/auth';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useDisconnect } from 'wagmi';
import { auth } from '@/configs/firebase';
import { restoreConsumerInfo } from '../../redux/consumerInfo';
import { restoreConsumerPoints } from '../../redux/consumerPoints';
import { usePrivy } from '@privy-io/react-auth';
import { PrivyAuthSuccessResponse } from '@/apis/privy/types';

export type WholeUserType = {
  operationType: string;
  providerId?: string;
};

export type BasicUserType = {
  token?: string;
  accessToken?: string;
};
export type SocialType = {
  provider_type: string;
  social_type: string;
  uid: string;
  email: string;
  display_name: string;
  photo_url: string;
};
type EvmType = {
  provider_type: string;
  web3_type: string;
  uid: string;
  address: string;
};
export type SocialLinksType = {
  google?: SocialType;
  linkedin?: SocialType;
  twitter?: SocialType;
  telegram?: SocialType;
  privy?: SocialType;
};

export type Web3LinksType = {
  evm?: {
    [key: string]: EvmType;
  };
};
export type BluwhaleUserLinksType = {
  social_links?: SocialLinksType;
  web3_links?: Web3LinksType;
};
export type BluwhaleUserType = BasicUserType &
  BluwhaleUserLinksType & {
    uid: string;
    uuid: string;
    user_type: UserType;
    provider_type?: 'web3' | 'social';
    sign_in_provider?: any;
    is_new_user?: boolean;
    photo_url?: string;
    email?: string;
    display_name?: string;
    create_at?: string;
    address?: string;
  };
export type FirebaseUserType = BasicUserType & User;

export type BluwhaleContextType = {
  user: BluwhaleUserType | undefined | null;
  firebaseUser: FirebaseUserType | undefined | null;
  axiosInstance?: AxiosInstance | undefined;
  onSetUser: ({ credential, user }: { credential?: UserCredential; user?: BluwhaleUserType }) => void;
  onUpdateUser: ({
    firebaseUser,
    token,
    user,
  }: {
    firebaseUser?: User;
    token?: string;
    user?: BluwhaleUserType;
  }) => void;
  onLogout: () => Promise<void>;
  onRefreshToken: () => Promise<string | undefined>;
  setLoading?: (loading: boolean) => void;
  loading?: boolean;
  onSetPrivyData: (data: PrivyAuthSuccessResponse) => void;
  privyData: PrivyAuthSuccessResponse | undefined | null;
};

const defaultContext: BluwhaleContextType = {
  user: null,
  firebaseUser: null,
  onSetUser: ({ credential, user }: { credential?: UserCredential; user?: BluwhaleUserType }) => {
    console.debug('onSetUser');
  },
  onUpdateUser: ({ firebaseUser, token, user }: { firebaseUser?: User; token?: string; user?: BluwhaleUserType }) => {
    console.debug('onUpdateUser');
  },
  onLogout: async () => {
    console.log('logout');
  },
  onRefreshToken: async () => {
    console.log('onRefreshToken');
    return '';
  },
  onSetPrivyData: (data: PrivyAuthSuccessResponse) => {
    console.debug('onSetPrivyData');
  },
  privyData: undefined,
};

const BluwhaleContext = createContext<BluwhaleContextType>(defaultContext);
function useBluwhale(): BluwhaleContextType {
  return useContext(BluwhaleContext);
}
export { useBluwhale, BluwhaleContext };

function BluwhaleProvider({ children }: { children: React.ReactNode }) {
  // const [user, setUser] = React.useState<any>(null);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { disconnectAsync } = useDisconnect();
  const [user, setUser] = useState<BluwhaleUserType>();
  const [userImp, setUserImp] = useState<any>(null);
  const [privyData, setPrivyData] = useState<PrivyAuthSuccessResponse>();
  const firebaseUserRef = useRef<any>(null);
  const { onCreateAxiosInstance, axiosInstance, onAddBearerToken, onRemoveBearerToken } = useInterceptors({
    baseURL: basicConfig.bluwhale.walletAPIUrl ?? '',
    onRefreshToken,
    onLogout,
  });
  const checkUser = async () => {
    const _user = typeof window !== 'undefined' ? sessionStorage.getItem('user') : undefined;
    if (_user) {
      setUser(JSON.parse(_user));
    } else {
      setLoading(false);
    }
    const _firebaseUser = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    firebaseUserRef.current = _firebaseUser ? JSON.parse(_firebaseUser) : null;
  };
  const { logout } = usePrivy();
  useEffect(() => {
    checkUser();
  }, []);
  async function onRefreshToken() {
    if (!auth.currentUser) {
      throw new Error('No user');
    }
    const token = await auth.currentUser?.getIdToken(true);
    console.log('Refreshed token:', token);
    await handleUpdateFirebaseUser(auth.currentUser, token);
    // await handleBearToken(token);
    return token;
  }
  async function onSetUser({
    credential,
    user: bluwhaleUser,
  }: {
    credential?: UserCredential;
    user?: BluwhaleUserType;
  }) {
    await onSetFirebaseUser(credential);
    if (bluwhaleUser?.address) {
      bluwhaleUser.address = bluwhaleUser.address.toLowerCase();
    }
    await onSetBluwhaleUser(bluwhaleUser);
    setLoading(false);
  }
  async function onUpdateUser({
    user: bluwhaleUser,
    firebaseUser,
    token,
  }: {
    user?: BluwhaleUserType;
    firebaseUser?: User;
    token?: string;
  }) {
    // link will not update firebaseUser in storage
    // await onSetFirebaseUserByUser(firebaseUser, token);
    if (user?.address) {
      user.address = user.address.toLowerCase();
    }
    await onSetBluwhaleUser(bluwhaleUser);
    setLoading(false);
  }
  async function handleUpdateFirebaseUser(_user?: User, _token?: string) {
    if (!_user || !_token) {
      return;
    }
    const concatUser = { ..._user, token: _token };
    // setUser({ user: concatUser });

    firebaseUserRef.current = concatUser;
    await handleBearToken(_token);
    await sessionStorage.setItem('firebaseUser', JSON.stringify(concatUser));
  }
  async function handleBearToken(token?: string) {
    if (!token) {
      return;
    }
    const bearerToken = `Bearer ${token}`;
    await onAddBearerToken(bearerToken);
  }
  async function onSetFirebaseUser(_credential?: UserCredential) {
    if (!_credential) {
      sessionStorage.removeItem('user');
      return;
    }
    const token = await _credential?.user.getIdToken();
    console.log('onSetUser', _credential);
    const concatUser = { ..._credential?.user, token };
    // setUser({ user: concatUser });

    firebaseUserRef.current = concatUser;
    await handleBearToken(token);
    await sessionStorage.setItem('firebaseUser', JSON.stringify(concatUser));
  }
  async function onSetFirebaseUserByUser(_user?: User, _token?: string) {
    if (!_user || !_token) {
      sessionStorage.removeItem('user');
      return;
    }
    const concatUser = { ..._user, token: _token };
    firebaseUserRef.current = concatUser;
    await handleBearToken(_token);
    await sessionStorage.setItem('firebaseUser', JSON.stringify(concatUser));
  }

  async function onSetBluwhaleUser(_user?: BluwhaleUserType) {
    if (!_user) {
      sessionStorage.removeItem('user');
      return;
    }
    if (_user.address) {
      _user.address = _user?.address?.toLowerCase();
    }
    console.debug('onSetBluwhaleUser', _user);
    setUser(_user);
    setUserImp(_user);
    await sessionStorage.setItem('user', JSON.stringify(_user));
  }
  async function onLogout() {
    console.log('onLogout');
    await disconnectAsync();
    //Privy logout
    await logout();

    setUser(undefined);
    setLoading(false);
    firebaseUserRef.current = null;
    sessionStorage.removeItem('firebaseUser');
    sessionStorage.removeItem('user');
    setUserImp(null);
    onRemoveBearerToken();
    await restoreConsumerInfo();
    await restoreConsumerPoints();
    const isEnterprise = router.pathname.includes('enterprise');
    const path = isEnterprise ? '/enterprise/login' : '/consumer/login?type=web3';
    router.push(path);
  }
  async function onSetPrivyData(data: PrivyAuthSuccessResponse) {
    setPrivyData(data);
  }

  const value = {
    user,
    userImp,
    firebaseUser: firebaseUserRef.current,
    axiosInstance,
    onLogout,
    onSetUser,
    onUpdateUser,
    onRefreshToken,
    loading,
    setLoading,
    onSetPrivyData,
    privyData,
  };
  return <BluwhaleContext.Provider value={value}>{children}</BluwhaleContext.Provider>;
}
export default BluwhaleProvider;
