import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define types for state
interface Spot {
  photo_url: string;
  display_name: string;
  address: string;
  avaiable_spots: number;
  available_rewards: number;
  current_level: number;
  next_level_spots: number;
  previous_referrals: number;
  price: number;
  queue_unclaimed_points: number;
  queued_users: number;
  refferals: number;
  required_referrals: number;
  total_points: number;

  total_rewards: number;

  total_spots: number;
  unclaimed_points: number;
}

interface SpotsState {
  spots: Spot[];
  loading: boolean;
  error: string | null;
}

// Define initial state
const initialState: SpotsState = {
  spots: [],
  loading: false,
  error: null,
};

// Create slice
const spotsSlice = createSlice({
  name: 'trendingSpots',
  initialState,
  reducers: {
    setSpotsList: (state, action: PayloadAction<any>) => {
      state.spots = action.payload;
    },
  },
});

// Export actions
export const { setSpotsList } = spotsSlice.actions;

// Export reducer
export default spotsSlice.reducer;
