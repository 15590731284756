const productionConfig = {
  isDemo: true,
  showSideInfo: process.env.NEXT_PUBLIC_SHOW_SIDE_INFO === 'true',
  demo: {
    name: 'tonyherrera.eth',
    address: '0x94de7e2c73529ebf3206aa3459e699fbcdfcd49b',
    avatarUrl: '/images/demo-avatar.png',
  },
  bluwhaleUrl: 'https://www.bluwhale.com',
  termsOfUse: 'https://www.bluwhale.com/privacy-policy',
  metaMaskUrl: ' https://metamask.io/',
  bluwhale: {
    officialUrl: 'https://www.bluwhale.com',
    walletAPIUrl: process.env.NEXT_PUBLIC_BLUWHALE_WALLET_API,
    apiKey: '765a055ec1038104b1b0cd80daf1e4a5',
    sdkUrl: 'https://bluwhale-api-p7glonikpa-de.a.run.app/js',
  },
  walletConnect: {
    projectId: '328d8861e7c3f4505757f621df929882',
  },
  scrollTop: {
    innerWidth: 1535,
    xl: 800,
    md: 200,
  },
  flag: {
    disconnect: true,
  },
  auth: {
    servicesBasicUrl: `${process.env.NEXT_PUBLIC_BLUWHALE_WALLET_API}/auth`,
    googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    linkedinClientId: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
    telegrambotName: process.env.NEXT_PUBLIC_TELEGRAM_BOT_NAME,
    telegrambotId: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID,
    telegramRedirectUrl: '/enterprise/login',
    // servicesBasicUrl: 'https://bluwhale-auth-service-q67p7dk34q-uc.a.run.app/api/v1/auth',
    // servicesBasicUrl: 'http://localhost:8000/api/v1/auth',
  },
  bluwhaleWallet: '0xB3a740F2809Aa925c4a76035944086D92d07C9bB',
};
const localConfig = {
  isDemo: true,
  showSideInfo: process.env.NEXT_PUBLIC_SHOW_SIDE_INFO === 'true',
  demo: {
    name: 'tonyherrera.eth',
    address: '0x94de7e2c73529ebf3206aa3459e699fbcdfcd49b',
    avatarUrl: '/images/demo-avatar.png',
  },
  bluwhaleUrl: 'http://localhost:8000/',
  // bluwhaleUrl: 'https://www.bluwhale.com',
  termsOfUse: 'https://www.bluwhale.com/privacy-policy',
  metaMaskUrl: ' https://metamask.io/',
  bluwhale: {
    officialUrl: 'https://www.bluwhale.com',
    walletAPIUrl:
      process.env.NEXT_PUBLIC_BLUWHALE_WALLET_API ||
      'https://web3-wallet-dashboard-api-dev-q67p7dk34q-uc.a.run.app/api/v1',
    apiKey: '765a055ec1038104b1b0cd80daf1e4a5',
    sdkUrl: 'https://bluwhale-api-p7glonikpa-de.a.run.app/js',
  },
  walletConnect: {
    projectId: '328d8861e7c3f4505757f621df929882',
  },
  scrollTop: {
    innerWidth: 1535,
    xl: 800,
    md: 200,
  },
  flag: {
    disconnect: true,
  },
  auth: {
    servicesBasicUrl: `${process.env.NEXT_PUBLIC_BLUWHALE_WALLET_API}/auth`,
    googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    linkedinClientId: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
    telegrambotName: process.env.NEXT_PUBLIC_TELEGRAM_BOT_NAME,
    telegrambotId: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID,
    telegramRedirectUrl: '/enterprise/login',
    // servicesBasicUrl: `${process.env.NEXT_PUBLIC_BLUWHALE_WALLET_API}/auth`,
    // servicesBasicUrl: 'https://bluwhale-auth-service-q67p7dk34q-uc.a.run.app/api/v1/auth',
  },
  bluwhaleWallet: '0xB3a740F2809Aa925c4a76035944086D92d07C9bB',
};
const bluPrivateNode = {
  id: 123454321,
  name: 'Blu PrivateNode',
  network: 'Blu PrivateNode',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['http://35.232.127.248:35454'] },
    public: { http: ['http://35.232.127.248:35454'] },
  },
};
const basicConfig = process.env.NODE_ENV === 'production' ? productionConfig : localConfig;
export { basicConfig, bluPrivateNode };

export * as privy from './privy';
