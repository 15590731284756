import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface enterpriseAttributesState {
  enterpriseAttributes: any;
}

const initialState: enterpriseAttributesState = {
  enterpriseAttributes: undefined,
};

export const enterpriseAttributesSlice = createSlice({
  name: 'enterpriseAttributes',
  initialState,
  reducers: {
    setEnterpriseAttributes: (state, action: PayloadAction<any>) => {
      state.enterpriseAttributes = action.payload;
    },
  },
});

export const { setEnterpriseAttributes } = enterpriseAttributesSlice.actions;

export default enterpriseAttributesSlice.reducer;
