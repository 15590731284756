import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnterprisePointsInfo } from '../types';

interface enterprisePointsState {
  enterprisePoints: EnterprisePointsInfo | undefined;
}

const initialState: enterprisePointsState = {
  enterprisePoints: undefined,
};

export const enterprisePointsSlice = createSlice({
  name: 'enterprisePoints',
  initialState,
  reducers: {
    setenterprisePoints: (state, action: PayloadAction<EnterprisePointsInfo | undefined>) => {
      state.enterprisePoints = action.payload;
    },
  },
});

export const { setenterprisePoints,  } = enterprisePointsSlice.actions;

export default enterprisePointsSlice.reducer;
