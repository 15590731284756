import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface enterpriseUsersState {
  enterpriseUsers: any;
}

const initialState: enterpriseUsersState = {
  enterpriseUsers: undefined,
};

export const enterpriseUsersSlice = createSlice({
  name: 'enterpriseUsers',
  initialState,
  reducers: {
    setenterpriseUsers: (state, action: PayloadAction<any>) => {
      state.enterpriseUsers = action.payload;
    },
  },
});

export const { setenterpriseUsers } = enterpriseUsersSlice.actions;

export default enterpriseUsersSlice.reducer;
