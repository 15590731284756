import { PrivyClientConfig } from '@privy-io/react-auth';
import { LoginMethodsType } from './types';

const appId: string = process.env.NEXT_PUBLIC_PRIVY_APP_ID ?? '';

//#region privy api
const apiConfig = {
  headers: {
    'privy-app-id': appId,
  },
  init: `https://auth.privy.io/api/v1/apps/${appId}`,
  sendMail: `https://auth.privy.io/api/v1/passwordless/init`,
  auth: `https://auth.privy.io/api/v1/passwordless/authenticate`,
};
//#endregion
const loginMethods: LoginMethodsType = ['email'];
const config: PrivyClientConfig = {
  // Customize Privy's appearance in your app
  appearance: {
    theme: 'dark',
    accentColor: '#00FFF0',
    logo: '/favicon-32x32.png',
  },
  // Create embedded wallets for users who don't have a wallet
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
  },
  loginMethods,
};

export { appId, config, apiConfig };
