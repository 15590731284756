import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConsumerInfoState {
  display_name: string;
  photo_url: string;
}

const initialState: ConsumerInfoState = {
  display_name: '',
  photo_url: '',
};

export const consumerInfoSlice = createSlice({
  name: 'ConsumerInfo',
  initialState,
  reducers: {
    changeConsumerPhoto: (state, action: PayloadAction<string>) => {
      state.photo_url = action.payload;
    },
    changeConsumerName: (state, action: PayloadAction<string>) => {
      state.display_name = action.payload;
    },
    restoreConsumerInfo: (state) => {
      state = initialState;
    },
  },
});

export const { changeConsumerPhoto, changeConsumerName,restoreConsumerInfo } = consumerInfoSlice.actions;

export default consumerInfoSlice.reducer;
