import { useBluwhale } from '@/context/BluwhaleContext';
import { getUserClaimStatus } from './../pages/api/userClaim';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosInstance } from 'axios';

interface Result {
  claimed: boolean | undefined;
  reward_blu: number | undefined;
  reward_usd: number | undefined;
}
interface CounterState {
  result: Result;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null | undefined;
}

const initialState: CounterState = {
  result: {
    claimed: undefined,
    reward_blu: undefined,
    reward_usd: undefined,
  },
  status: 'idle',
  error: null,
};
export const fetchClaimStatus = createAsyncThunk(
  'userClaimStatus/fetchClaimStatus',
  async ({ myAddress, instance }: { myAddress: string; instance: AxiosInstance }) => {
    const result = await getUserClaimStatus(myAddress, instance);
    return result;
  },
);

export const userClaimStatusSlice = createSlice({
  name: 'userClaimStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaimStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClaimStatus.fulfilled, (state, action: PayloadAction<Result>) => {
        state.status = 'succeeded';
        state.result = action.payload;
      })
      .addCase(fetchClaimStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message;
      });
  },
});

export const {} = userClaimStatusSlice.actions;

export default userClaimStatusSlice.reducer;
