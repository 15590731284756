import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConsumerPointsInfo, ConsumerReferralCode, ConsumerReferralUser } from '../types';

interface consumerPointsState {
  consumerPoints: ConsumerPointsInfo | undefined;
  consumerReferralCode: ConsumerReferralCode | undefined;
  consumerReferralUser: {
    users: ConsumerReferralUser[] | undefined;
  };
}

const initialState: consumerPointsState = {
  consumerPoints: undefined,
  consumerReferralCode: undefined,
  consumerReferralUser: {
    users: undefined,
  },
};

export const consumerPointsSlice = createSlice({
  name: 'consumerPoints',
  initialState,
  reducers: {
    setconsumerPoints: (state, action: PayloadAction<ConsumerPointsInfo | undefined>) => {
      state.consumerPoints = action.payload;
    },
    setconsumerReferralCode: (state, action: PayloadAction<ConsumerReferralCode | undefined>) => {
      state.consumerReferralCode = action.payload;
    },
    setconsumerReferralUser: (state, action: PayloadAction<ConsumerReferralUser[] | undefined>) => {
      state.consumerReferralUser.users = action.payload;
    },
    restoreConsumerPoints: (state) => {
      state = initialState;
    },
  },
});

export const { setconsumerPoints, setconsumerReferralCode, setconsumerReferralUser, restoreConsumerPoints } =
  consumerPointsSlice.actions;

export default consumerPointsSlice.reducer;
