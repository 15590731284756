import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EnterpriseDetailState {
  enterpriseDetail: any;
}

const initialState: EnterpriseDetailState = {
  enterpriseDetail: undefined,
};

export const enterpriseDetailSlice = createSlice({
  name: 'enterpriseDetail',
  initialState,
  reducers: {
    setEnterpriseDetail: (state, action: PayloadAction<any>) => {
      state.enterpriseDetail = action.payload;
    },
  },
});

export const { setEnterpriseDetail } = enterpriseDetailSlice.actions;

export default enterpriseDetailSlice.reducer;
