import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const prodConfig = {
  apiKey: 'AIzaSyAt5pTGkbXQzw_VDIh8K_MXcJHBX3wgf_U',
  authDomain: 'web3-wallet-dashboard.firebaseapp.com',
  projectId: 'web3-wallet-dashboard',
  storageBucket: 'web3-wallet-dashboard.appspot.com',
  messagingSenderId: '968372383603',
  appId: '1:968372383603:web:1e414fcbe76b45cf739f06',
  measurementId: 'G-01388HFNNC',
};
const devConfig = {
  apiKey: 'AIzaSyAt5pTGkbXQzw_VDIh8K_MXcJHBX3wgf_U',
  authDomain: 'web3-wallet-dashboard.firebaseapp.com',
  projectId: 'web3-wallet-dashboard',
  storageBucket: 'web3-wallet-dashboard.appspot.com',
  messagingSenderId: '968372383603',
  appId: '1:968372383603:web:1e414fcbe76b45cf739f06',
  measurementId: 'G-01388HFNNC',
};

const firebaseConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

const firebaseApp = initializeApp(firebaseConfig);
console.log('process.env.NODE_ENV', process.env.NODE_ENV);
const auth = getAuth(firebaseApp);
export { firebaseApp, auth };
