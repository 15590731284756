import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EnterpriseProjectInfoState {
  projectInfo: any;
}

const initialState: EnterpriseProjectInfoState = {
  projectInfo: undefined,
};

export const enterpriseProjectInfoSlice = createSlice({
  name: 'enterpriseProjectInfo',
  initialState,
  reducers: {
    setEnterpriseProjectInfo: (state, action: PayloadAction<any>) => {
      state.projectInfo = action.payload;
    },
  },
});

export const { setEnterpriseProjectInfo } = enterpriseProjectInfoSlice.actions;

export default enterpriseProjectInfoSlice.reducer;
