import { basicConfig } from '@/configs';
import axios, { AxiosInstance } from 'axios';

export const getUserCampaigns = async (wallet: string, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.get(`wallets/inbox/${wallet}/`, {});
  return response?.data;
};

export const getUserClaimStatus = async (wallet: string, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`${basicConfig.bluwhale.walletAPIUrl}/wallets/claim_status/`, { wallet });
  return response?.data;
};

export const claimUserRewards = async (wallet: string) => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/claim_wallet_profile/`,
    { wallet },
    {
      headers,
    },
  );
  return response?.data;
};
