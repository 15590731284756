import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface enterpriseOutReachUsersState {
  enterpriseOutReachUsers: any;
}

const initialState: enterpriseOutReachUsersState = {
  enterpriseOutReachUsers: undefined,
};

export const enterpriseOutReachUsersSlice = createSlice({
  name: 'enterpriseOutReachUsers',
  initialState,
  reducers: {
    setEnterpriseOutReachUsers: (state, action: PayloadAction<any>) => {
      state.enterpriseOutReachUsers = action.payload;
    },
  },
});

export const { setEnterpriseOutReachUsers } = enterpriseOutReachUsersSlice.actions;

export default enterpriseOutReachUsersSlice.reducer;
