import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface spotItem {
  photo_url: string;
  display_name: string;
  address: string;
  occupied_spot: number;
}

interface occupiedSpotsState {
  spotslist: spotItem[];
  celebrate: boolean;
  initialized: boolean;
}

const initialState: occupiedSpotsState = {
  spotslist: [],
  celebrate: false,
  initialized: false,
};

export const occupiedSpotsSlice = createSlice({
  name: 'occupiedSpots',
  initialState,
  reducers: {
    setOccupiedSpotsList: (state, action: PayloadAction<any>) => {
      if (action.payload?.length > state.spotslist.length && state.initialized) {
        state.celebrate = true;
      }
      state.spotslist = action.payload;
    },
    hideCelebrate: (state) => {
      state.celebrate = false;
    },
    showCelebrate: (state) => {
      state.celebrate = true;
    },
    setInitialized: (state) => {
      state.initialized = true;
    },
  },
});

export const {
  setOccupiedSpotsList,
  hideCelebrate,
  showCelebrate,
  setInitialized: occupiedInitialized,
} = occupiedSpotsSlice.actions;

export default occupiedSpotsSlice.reducer;
