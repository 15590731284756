import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CounterState {
  showProfit: boolean;
  addedBlu: number;
  totalBlu:number;
  projectBlu: number;
}

const initialState: CounterState = {
  showProfit: false,
  addedBlu: 0,
  totalBlu:0,
  projectBlu:0,
};

export const claimProfitSlice = createSlice({
  name: 'claimProfit',
  initialState,
  reducers: {
    toggleShowBlu: (state) => {
      state.showProfit = !state.showProfit;
    },
    displayShow: (state)=>{
      state.showProfit = true;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.addedBlu = action.payload;
    },
    increaseBlu: (state, action: PayloadAction<number>)=>{
      state.totalBlu += action.payload;
    },
    addProjectBlu:(state, action: PayloadAction<number>)=>{
      state.projectBlu += action.payload;
    },
  },
});

export const { toggleShowBlu,incrementByAmount,displayShow,increaseBlu,addProjectBlu } = claimProfitSlice.actions;

export default claimProfitSlice.reducer;
