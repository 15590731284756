'use client';
import { useBluwhale } from '@/context/BluwhaleContext';
import axios, { AxiosInstance } from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

export type useInterceptorsOptions = {
  baseURL: string;
  onRefreshToken?: () => Promise<string | undefined>;
  onLogout?: () => Promise<void>;
};

export function useInterceptors({ baseURL, onRefreshToken, onLogout }: useInterceptorsOptions) {
  const router = useRouter();
  const instanceRef = useRef<any>();
  useEffect(() => {
    if (instanceRef.current) {
      return;
    }
    init();
  }, []);
  function init() {
    const token = getBearToken();
    createAxiosInstance(token);
  }

  function createAxiosInstance(_bearerToken?: string) {
    let bearerToken = _bearerToken;
    if (!_bearerToken) {
      const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
      const token = user ? JSON.parse(user).accessToken : undefined;
      bearerToken = token ? `Bearer ${token}` : undefined;
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `${bearerToken}`,
    };
    console.debug('headers', headers);

    const instance = axios.create({ baseURL, headers });

    instance.interceptors.response.use(
      (response) => response,
      async (error: any) => {
        // console.debug('interceptors error', error);
        if (error?.response?.status && error?.response?.status === 401) {
          // handle 401 error here
          const res = await handle401Error(instance, error);
          // console.log('interceptors error res', res);
          return res;
        }
        return Promise.reject(error);
      },
    );
    instanceRef.current = instance;
    return instance;
  }
  async function onAddBearerToken(_bearerToken?: string) {
    let bearerToken = _bearerToken;
    if (!_bearerToken) {
      bearerToken = getBearToken();
    }
    if (!bearerToken) {
      return;
    }
    if (!instanceRef.current) {
      createAxiosInstance();
    }
    instanceRef.current.defaults.headers.Authorization = bearerToken;
  }
  async function onRemoveBearerToken() {
    if (!instanceRef.current) {
      createAxiosInstance();
    }
    delete instanceRef.current.defaults.headers.Authorization;
  }
  async function handle401Error(instance: AxiosInstance, error: any) {
    console.debug('handle401Error', error);
    try {
      if (!onRefreshToken) {
        throw new Error('onRefreshToken is not defined');
      }
      const newToken = await onRefreshToken();
      const bearToken = `Bearer ${newToken}`;
      await onAddBearerToken(bearToken);

      error.config.headers['Authorization'] = `Bearer ${newToken}`;

      // move retry logic to react-query
      // const response = await axios(error.config);
      // console.log('handle401Error response', response);

      // return response;
      return undefined;
    } catch (refreshError) {
      console.error('Failed to refresh token', refreshError);
      if (onLogout) {
        onLogout();
      }
      // router.push('/login');
      return Promise.reject(refreshError);
    }
  }
  function getBearToken() {
    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    return token ? `Bearer ${token}` : undefined;
  }
  return {
    onCreateAxiosInstance: createAxiosInstance,
    onRemoveBearerToken,
    onAddBearerToken,
    axiosInstance: instanceRef.current,
  };
}
