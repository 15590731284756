import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserCredential } from 'firebase/auth';

export interface firebaseUSerSocialInfo {
  user?: User | any;
  credential?: UserCredential;
}

export interface userSocialInfo {
  aggregateVerifier: string;
  appState: string;
  dappShare: string;
  email: string;
  idToken: string;
  isMfaEnabled: boolean;
  name: string;
  oAuthAccessToken: string;
  oAuthIdToken: string;
  profileImage: string;
  typeOfLogin: string;
  verifier: string;
  verifierId: string;
  firebaseUser?: firebaseUSerSocialInfo;
}

const initialState: userSocialInfo = {
  aggregateVerifier: '',
  appState: '',
  dappShare: '',
  email: '',
  idToken: '',
  isMfaEnabled: false,
  name: '',
  oAuthAccessToken: '',
  oAuthIdToken: '',
  profileImage: '',
  typeOfLogin: '',
  verifier: '',
  verifierId: '',
};

export const userSocialInfoSlice = createSlice({
  name: 'userSocialInfoSlice',
  initialState,
  reducers: {
    setUserSocialInfo: (state, action: PayloadAction<userSocialInfo>) => {
      state = action.payload;
    },
  },
});

export const { setUserSocialInfo } = userSocialInfoSlice.actions;

export default userSocialInfoSlice.reducer;
