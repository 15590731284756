import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface claimPointState {
  addedPoints: string;
}

const initialState: claimPointState = {
  addedPoints: '',
};

export const claimPointSlice = createSlice({
  name: 'claimPoints',
  initialState,
  reducers: {
    setPoints: (state, action: PayloadAction<string>) => {
      state.addedPoints = action.payload;
    },
  },
});

export const { setPoints } = claimPointSlice.actions;

export default claimPointSlice.reducer;
