import '@fontsource/poppins/400.css';
import '@fontsource/poppins/700.css';
import { Provider } from 'react-redux';
import store from '../redux/store';

import { GoogleAnalytics } from 'nextjs-google-analytics';
import '@rainbow-me/rainbowkit/styles.css';
import {
  connectorsForWallets,
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
  WalletList,
  Theme,
} from '@rainbow-me/rainbowkit';
import {
  ledgerWallet,
  safeWallet,
  rabbyWallet,
  injectedWallet,
  metaMaskWallet,
  walletConnectWallet,
  coinbaseWallet,
  rainbowWallet,
  imTokenWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { ChakraProvider, DarkMode, LightMode, StyleFunctionProps } from '@chakra-ui/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  arbitrum,
  arbitrumGoerli,
  avalanche,
  avalancheFuji,
  bsc,
  bscTestnet,
  fantom,
  fantomTestnet,
  foundry,
  goerli,
  mainnet,
  optimism,
  optimismGoerli,
  polygon,
  polygonMumbai,
  sepolia,
} from '@wagmi/core/chains';
import { extendTheme } from '@chakra-ui/react';
import { publicProvider } from 'wagmi/providers/public';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement,
  Filler,
} from 'chart.js';
import 'tailwindcss/tailwind.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { mergeDeepLeft } from 'ramda';

import { buttonTheme } from 'theme/components/Button';
import { MoralisConfig, MoralisProvider } from '@moralisweb3/react';
import Moralis from 'moralis';
import './styles.css';
import { useEffect, useState } from 'react';
import BluwhaleProvider, { useBluwhale } from '@/context/BluwhaleContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { basicConfig, privy } from '@/configs';
import ReactGA4 from 'react-ga4';
import { auth } from '@/configs/firebase';
import { PrivyProvider } from '@privy-io/react-auth';
import { useRouter } from 'next/router';

const WALLET_CONNECT_PROJECT_ID = '328d8861e7c3f4505757f621df929882';
const localStorageMode = typeof window !== 'undefined' ? localStorage.getItem('chakra-ui-color-mode') : undefined;

const APP_NAME = 'Bluwhale';

const walletTheme = mergeDeepLeft(darkTheme(), {
  colors: {
    modalBackground: '#16182e',
    accentColor: '#9da5f2',
    menuItemBackground: '#808aff14',
  },
  radii: {
    modal: '4px',
    menuButton: '4px',
  },
} as Theme);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  Filler,
  Title,
  Tooltip,
  Legend,
);
const { publicClient, chains } = configureChains(
  [
    arbitrum,
    arbitrumGoerli,
    avalanche,
    avalancheFuji,
    bsc,
    bscTestnet,
    fantom,
    fantomTestnet,
    foundry,
    goerli,
    mainnet,
    optimism,
    optimismGoerli,
    polygon,
    polygonMumbai,
    sepolia,
  ],
  [publicProvider()],
);

const recommendedWalletList: WalletList = [
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      safeWallet({ chains }),
      rabbyWallet({ chains }),
      metaMaskWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      walletConnectWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
    ],
  },
];

const othersWalletList: WalletList = [
  {
    groupName: 'Others',
    wallets: [
      coinbaseWallet({ appName: APP_NAME, chains }),
      ledgerWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      rainbowWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      imTokenWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
    ],
  },
];

const connectors = connectorsForWallets([...recommendedWalletList, ...othersWalletList]);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
const themeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config: themeConfig,
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        color: 'default',
        bg: '#000',
      },
    }),
  },
  components: {
    Button: buttonTheme,
    // Progress: {
    //   baseStyle: {
    //     filledTrack: {
    //       bg: '#8C8ACB',
    //       rounded: 'full',
    //     },
    //   },
    // },
    // Tag: {
    //   baseProps: {
    //     boxShadow: 'none',
    //   },
    // },
  },
  fonts: {
    body: `'Poppins', sans-serif`,
  },
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [didEffect, setDidEffect] = useState(false);
  const moralisConfig: MoralisConfig = {
    apiKey: process.env.NEXT_PUBLIC_MORALIS_API_KEY || '',
  };
  if (!Moralis.Core.isStarted) {
    Moralis.start(moralisConfig);
  }
  useEffect(() => {
    if (localStorageMode && localStorageMode !== 'dark') {
      localStorage.setItem('chakra-ui-color-mode', 'dark');
    }
    ReactGA4.initialize('G-1GYPKYZL6T');
  }, [localStorageMode]);

  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
  });
  const router = useRouter();
  const path = `${router.asPath.split('/')[1]}/ ${router.asPath.split('/')[2]}`;

  useEffect(() => {
    function handleResize() {
      if (typeof window !== 'undefined') {
        const screenWidth = window.innerWidth;
        if (screenWidth < 1600) {
          if (!router.pathname.includes('login') && !router.pathname.includes('link')) {
            //@ts-ignore
            document.body.style.zoom = '80%';
          }
        } else {
          //@ts-ignore
          document.body.style.zoom = '100%'; // Reset zoom if greater than 1000 pixels
        }
      }
    }

    // Initial call
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    console.log('router.basePath', path);
    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [path]); // Empty dependency array to ensure effect runs only once

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <GoogleAnalytics trackPageViews />
          <ChakraProvider resetCSS={false} theme={theme}>
            <DarkMode>
              <WagmiConfig config={config}>
                <RainbowKitProvider theme={walletTheme} chains={chains} modalSize="compact">
                  <MoralisProvider config={moralisConfig}>
                    <PrivyProvider appId={privy.appId} config={privy.config}>
                      <SessionProvider session={pageProps.session} refetchInterval={0}>
                        <BluwhaleProvider>
                          <Component {...pageProps} />
                        </BluwhaleProvider>
                      </SessionProvider>
                    </PrivyProvider>
                  </MoralisProvider>
                </RainbowKitProvider>
              </WagmiConfig>
            </DarkMode>
          </ChakraProvider>
        </QueryClientProvider>
      </Provider>
    </>
  );
};

export default MyApp;
