import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface enterpriseSimilarProjectsState {
  enterpriseSimilarProjects: any;
}

const initialState: enterpriseSimilarProjectsState = {
  enterpriseSimilarProjects: undefined,
};

export const enterpriseSimilarProjectsSlice = createSlice({
  name: 'enterpriseSimilarProjects',
  initialState,
  reducers: {
    setEnterpriseSimilarProjects: (state, action: PayloadAction<any>) => {
      state.enterpriseSimilarProjects = action.payload;
    },
  },
});

export const { setEnterpriseSimilarProjects } = enterpriseSimilarProjectsSlice.actions;

export default enterpriseSimilarProjectsSlice.reducer;
