import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
  UserCredential,
  browserSessionPersistence,
} from 'firebase/auth';
import { firebaseApp } from '@/configs/firebase/config';
import { FirebaseProviderType, FirebaseProvidersType, socialKeys } from './types';
import { TokenResponse, useGoogleLogin, GoogleLogin, CredentialResponse } from '@react-oauth/google';

const auth = getAuth(firebaseApp);
// set persistence to browser session
auth.setPersistence(browserSessionPersistence);
const login = async (provider: any): Promise<UserCredential> => {
  const credential = await signInWithPopup(auth, provider);

  return credential;
};
const web3Login = async (): Promise<UserCredential | undefined> => {
  //TODO: Implement web3 login
  const credential = undefined;

  return credential;
};
/**
 *
 * @returns
 * @description
 * Because of the way GoogleAuthProvider will override
 * the default user who is logged in with other providers when the email is the same,
 * we need to use re-write the custom login function for google login to avoid this.
 */
const googleLogin = async (): Promise<UserCredential | undefined> => {
  const _onSignIn = async (token: string) => {
    //TODO: Implement sign in
    return token;
  };
  const _onGetFirebaseToken = async (token: string) => {
    //TODO: Implement get firebase token
    return token;
  };
  // const _onSuccess = async (res: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
  const _onSuccess = async (res: CredentialResponse) => {
    console.log('google login success', res);
    // const accessToken = res.access_token;
    // const firebaseToken = await _onGetFirebaseToken(accessToken);
    // const userCredential = await _onSignIn(firebaseToken);
  };
  // const _onError = async (res: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
  const _onError = async () => {
    console.log('google login error', res);
  };
  //TODO: Implement google login
  // useGoogleLogin({ flow: 'implicit', onSuccess: _onSuccess, onError: _onError });
  const res = await GoogleLogin({ onSuccess: _onSuccess, onError: _onError });
  console.log('google login res', res);
  return undefined;
};
const logout = async () => {
  await signOut(auth);
};
const socialLoginProviders: FirebaseProvidersType = {
  twitter: {
    name: 'Twitter',
    enabled: true,
    logo: '/images/icon/twitter.png',
  },
  google: {
    name: 'Google',
    enabled: true,
    logo: '/images/icon/google.png',
  },
  facebook: {
    name: 'Facebook',
    enabled: false,
    logo: 'https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/facebook_circle-512.png',
  },
  telegram: {
    name: 'Telegram',
    enabled: true,
    logo: '/images/telegram-icon.svg',
  },
  linkedin: {
    name: 'Linkedin',
    enabled: true,
    logo: '/images/linkedin-icon.svg',
  },
};
const web3LoginProviders: FirebaseProvidersType = {
  metaMask: {
    name: 'MetaMask',
    enabled: true,
    logo: 'https://cdn4.iconfinder.com/data/icons/logos-brands-5/24/metamask-512.png',
  },
  walletConnect: {
    name: 'WalletConnect',
    enabled: true,
    logo: 'https://cdn4.iconfinder.com/data/icons/logos-brands-5/24/walletconnect-512.png',
  },
  coinbase: {
    name: 'Coinbase',
    enabled: true,
    logo: 'https://cdn4.iconfinder.com/data/icons/logos-brands-5/24/coinbase-512.png',
  },
};

const firebaseAuthProviders: { [key in socialKeys]: any } = {
  twitter: new TwitterAuthProvider(),
  google: new GoogleAuthProvider(),
  facebook: new FacebookAuthProvider(),
};
const socialLoginItems = Object.values(socialLoginProviders);
const web3LoginItems = Object.values(web3LoginProviders);
export { socialLoginProviders, web3LoginProviders, socialLoginItems, web3LoginItems, firebaseAuthProviders };
