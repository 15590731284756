import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface enterpriseCompaignsState {
  enterpriseCompaigns: any;
}

const initialState: enterpriseCompaignsState = {
  enterpriseCompaigns: undefined,
};

export const enterpriseCompaignsSlice = createSlice({
  name: 'enterpriseCompaigns',
  initialState,
  reducers: {
    setEnterpriseCompaigns: (state, action: PayloadAction<any>) => {
      state.enterpriseCompaigns = action.payload;
    },
  },
});

export const { setEnterpriseCompaigns } = enterpriseCompaignsSlice.actions;

export default enterpriseCompaignsSlice.reducer;
